/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { UploadStatus } from "./FileUpload";
import { NewFileInfo } from "Api";
import { Alert } from "baseui/icon";
import { SIZE, Spinner } from "baseui/spinner";
import { OverflowActions, OverflowMenu } from "../Shared/OverflowMenu";
import { RedactedText } from "../Shared/RedactedText";
import { TreeView, TreeLabelInteractable, TreeNode } from "baseui/tree-view";
import { EndAnchoredRow } from "../DesignSystem/Containers";
import { LabelMedium } from "baseui/typography";
import { Input } from "baseui/input";
import {
  FileNode,
  FileTreeNode,
  FolderNode,
  isRootNode,
  ModelFileTreeAction,
  ROOT_FILE_TREE_NODE_ID,
} from "./ModelFileTreeState";
import AddFilesModal from "./AddFilesModal";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { BlockProps } from "baseui/block";
import { themedUseStyletron as useStyletron } from "../DesignSystem/LightTheme";

const itemProps: BlockProps = {
  height: "scale1000",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
};
const narrowItemProps: BlockProps = {
  height: "scale1000",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overrides: {
    Block: {
      style: {
        width: "3rem",
        flexGrow: 0,
      },
    },
  },
};

const FileUploadLabel = ({
  fileInfo,
  status,
  redacted,
}: {
  fileInfo: NewFileInfo;
  status?: UploadStatus;
  redacted: boolean;
}): JSX.Element => {
  return (
    <div style={{ display: "flex" }}>
      {status !== UploadStatus.SUCCESSFUL &&
        (status === UploadStatus.IN_PROGRESS ? (
          <Spinner $size={SIZE.small} />
        ) : (
          <Alert color="red" size={30} />
        ))}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexDirection: "column",
        }}
      >
        <LabelMedium paddingLeft={"1rem"}>
          {redacted ? <RedactedText /> : fileInfo.name}
        </LabelMedium>
      </div>
    </div>
  );
};

const FileRow = ({
  fileNode,
  dispatch,
}: {
  fileNode: FileNode;
  dispatch: React.Dispatch<ModelFileTreeAction>;
}): JSX.Element => {
  const file = fileNode.file;

  const handleClick = (): void => {
    if (!fileNode.isSelected && !file.redacted) {
      dispatch(["selectFileTreeNode", fileNode]);
    }
  };

  const fileOverflowActions = React.useMemo<OverflowActions[]>(() => {
    return [
      {
        label: "Remove",
        onClick: () => dispatch(["deleteFileTreeNode", fileNode]),
      },
    ];
  }, [fileNode, dispatch]);

  // TODO: Make this a color constant. Add to theme.
  const backgroundColor = fileNode.isSelected
    ? "rgba(222, 236, 249, 1)"
    : undefined;

  // TODO: Componentize the flex grid and its contents if possible.
  return (
    <div style={{ backgroundColor: backgroundColor, width: "100%" }}>
      <TreeLabelInteractable>
        <EndAnchoredRow style={{ alignItems: "center" }}>
          <div
            style={{ width: "100%", cursor: "pointer" }}
            onClick={() => handleClick()}
          >
            <FlexGrid
              flexGridColumnCount={1}
              flexGridColumnGap="scale200"
              flexGridRowGap="scale800"
            >
              <FlexGridItem {...itemProps}>
                <FileUploadLabel
                  redacted={file?.redacted}
                  fileInfo={file?.info}
                  status={file?.status}
                />
              </FlexGridItem>
            </FlexGrid>
          </div>
          <OverflowMenu
            overflowActions={fileOverflowActions}
            disabled={file?.redacted}
          />
        </EndAnchoredRow>
      </TreeLabelInteractable>
    </div>
  );
};

const FolderRow = ({
  folderNode,
  dispatch,
}: {
  folderNode: FolderNode;
  dispatch: React.Dispatch<ModelFileTreeAction>;
}): JSX.Element => {
  const [addFilesModalIsOpen, setAddFilesModalIsOpen] = React.useState(false);

  const folderOverflowActions = React.useMemo<OverflowActions[]>(() => {
    const folderActions = [
      {
        label: "Add folder",
        onClick: () => dispatch(["addFolder", folderNode]),
      },
      {
        label: "Add files",
        onClick: () => setAddFilesModalIsOpen(true),
      },
    ];
    if (folderNode.id != ROOT_FILE_TREE_NODE_ID) {
      folderActions.push({
        label: "Rename",
        onClick: () => dispatch(["startEditFolderName", folderNode]),
      });
      folderActions.push({
        label: "Remove",
        onClick: () => dispatch(["deleteFileTreeNode", folderNode]),
      });
    }
    return folderActions;
  }, [dispatch, folderNode]);

  const handleFolderNameEdit = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      dispatch([
        "editFolderName",
        { editedFolder: folderNode, newTitle: event.currentTarget.value },
      ]);
    },
    [dispatch, folderNode]
  );

  const handleKeyDown = React.useCallback(
    (keyEvent: React.KeyboardEvent): void => {
      if (keyEvent.key === "Enter") {
        dispatch(["commitFolderEdit", folderNode]);
      } else if (keyEvent.key === "Escape") {
        dispatch(["cancelFolderEdit", folderNode]);
      }
    },
    [dispatch, folderNode]
  );

  const handleClick = (): void => {
    if (!folderNode.isSelected) {
      dispatch(["selectFileTreeNode", folderNode]);
    }
  };

  const handleBlur = React.useCallback(() => {
    dispatch(["commitFolderEdit", folderNode]);
  }, [dispatch, folderNode]);

  const folderIcon = new URL("../resources/images/Folder.svg", import.meta.url);

  const [css, theme] = useStyletron();

  // TODO: Componentize the flex grid and its contents if possible.
  return (
    <div
      className={css({
        borderColor: folderNode.isSelected
          ? theme.colors.selectionBackgroundPrimary
          : undefined,
        width: "100%",
      })}
    >
      <TreeLabelInteractable>
        <EndAnchoredRow style={{ alignItems: "center" }}>
          {folderNode.isEditing ? (
            <Input
              value={folderNode.newTitle}
              onChange={handleFolderNameEdit}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
            />
          ) : isRootNode(folderNode) ? (
            <div
              style={{ width: "100%", cursor: "pointer" }}
              onClick={() => handleClick()}
            >
              <FlexGrid
                flexGridColumnCount={2}
                flexGridColumnGap="scale200"
                flexGridRowGap="scale800"
              >
                <FlexGridItem {...narrowItemProps}>
                  <img
                    style={{ width: "1.1rem" }}
                    src={folderIcon.toString()}
                  />
                </FlexGridItem>
                <FlexGridItem {...itemProps}>
                  <LabelMedium>{folderNode.title}</LabelMedium>
                </FlexGridItem>
              </FlexGrid>
            </div>
          ) : (
            <div style={{ width: "100%", cursor: "default" }}>
              <FlexGrid
                flexGridColumnCount={2}
                flexGridColumnGap="scale200"
                flexGridRowGap="scale800"
              >
                <FlexGridItem {...narrowItemProps}>
                  <img
                    style={{ width: "1.1rem" }}
                    src={folderIcon.toString()}
                  />
                </FlexGridItem>
                <FlexGridItem {...itemProps}>
                  <LabelMedium>{folderNode.title}</LabelMedium>
                </FlexGridItem>
              </FlexGrid>
            </div>
          )}

          <OverflowMenu overflowActions={folderOverflowActions} />
        </EndAnchoredRow>
        <AddFilesModal
          isOpen={addFilesModalIsOpen}
          folderNode={folderNode}
          dispatch={dispatch}
          onClose={() => setAddFilesModalIsOpen(false)}
        />
      </TreeLabelInteractable>
    </div>
  );
};

const TreeRow = ({
  node,
  dispatch,
}: {
  node: FileTreeNode;
  dispatch: React.Dispatch<ModelFileTreeAction>;
}): JSX.Element => {
  return node.type === "File" ? (
    <FileRow fileNode={node} dispatch={dispatch} />
  ) : (
    <FolderRow folderNode={node} dispatch={dispatch} />
  );
};

function createBasewebNode(
  fileTreeNode: FileTreeNode,
  dispatch: React.Dispatch<ModelFileTreeAction>
): TreeNode<FileTreeNode> {
  const value: TreeNode<FileTreeNode> = {
    ...fileTreeNode,
    info: fileTreeNode,
    // eslint-disable-next-line react/display-name
    label: (node) => <TreeRow node={node.info} dispatch={dispatch} />,
    children:
      fileTreeNode.type === "Folder"
        ? fileTreeNode.children?.map((child) =>
            createBasewebNode(child, dispatch)
          )
        : null,
  };
  return value;
}

export const ModelFileTree = ({
  fileTreeRoot,
  dispatch,
}: {
  fileTreeRoot?: FolderNode;
  dispatch: React.Dispatch<ModelFileTreeAction>;
}): JSX.Element => {
  const modelFileTreeData = React.useMemo((): TreeNode[] => {
    if (fileTreeRoot) {
      const basewebRootNode = createBasewebNode(fileTreeRoot, dispatch);
      return [basewebRootNode];
    } else {
      return [];
    }
  }, [fileTreeRoot, dispatch]);

  return (
    <TreeView
      data={modelFileTreeData}
      onToggle={(node) => {
        if (node.info.type === "Folder") {
          const folderNode: FolderNode = node.info;
          dispatch([
            "setFolderExpanded",
            { folderToChange: folderNode, expanded: !folderNode.isExpanded },
          ]);
        }
      }}
    />
  );
};
