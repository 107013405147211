/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import {
  FileIdFolder,
  FileInfo,
  ModelFileFolder,
  ModelInfo,
  ModelInfoInput,
  ModelMetadata,
  ModelMetadataSchema,
  NewFileInfo,
  SecurityMarkings,
} from "Api";
import { CancelTokenSource } from "axios";
import {
  emptyModelInfoInput,
  emptyModelMetadataSchema,
  isModelChanged,
} from "../Api/ApiExtensions";
import { FieldSpec } from "../Shared/FieldSpec";
import { deepClone } from "../Utils/Common";
import { equalIgnoreCase } from "../Utils/Sort";
import { UUID } from "../Utils/Types";
import {
  FilePath,
  FileUpload,
  toClearedModelFileUpload,
  toFileInfoUpload,
  toFileUploadFailure,
  UploadStatus,
} from "./FileUpload";
import * as Tree from "./ModelFileTreeState";
import {
  allMetadataFields,
  applyMetadataProperty,
  concatUnspecifiedFields,
  flattenPresentMetadata,
  TITLE_FIELD,
} from "./ModelMetadataFields";

export class ModelContent {
  fileTreeRoot: Tree.FolderNode = convertToFileTree();
  model: ModelInfoInput = emptyModelInfoInput();
}

export class ModelFields {
  customSchema: ModelMetadataSchema = emptyModelMetadataSchema();
  all: FieldSpec[] = defineAllFields();
  tab: FieldSpec[] = defineFieldsInTab(this.all);
}

export class ModelDetailsState {
  fields: ModelFields = new ModelFields();
  original: ModelContent = new ModelContent();
  edited: ModelContent = new ModelContent();
  metadataValues: Record<string, any> = {};
  modelChanged: boolean = false;
  selectedFileTreeNode: Tree.FileTreeNode;
  creationDate: string;
  lastSaveDate: string;

  constructor(id: UUID) {
    this.edited.model.id = id;
    this.original.model.id = id;
  }
}

function applyEdit(
  state: ModelDetailsState,
  newEdit: ModelContent
): ModelDetailsState {
  return {
    ...state,
    edited: newEdit,
    modelChanged: isModelChanged(state.original.model, newEdit.model),
    metadataValues: flattenPresentMetadata(newEdit.model.metadata),
  };
}

export function applyKeywords(
  fileUploads: FileUpload[],
  toEdited: ModelMetadata
): ModelMetadata {
  const newModelMetadata = { ...toEdited };

  const newKeywords = new Set(
    fileUploads
      .flatMap((file) => file.metadataScan?.metadata?.keywords)
      .concat(newModelMetadata.keywords)
      .filter((keyword) => keyword !== undefined && keyword !== null)
      .map((keyword) => keyword.trim())
  );

  newModelMetadata.keywords = Array.from(newKeywords);
  return newModelMetadata;
}

function defineFieldsInTab(fields: FieldSpec[]): FieldSpec[] {
  return fields.filter((field) => field !== TITLE_FIELD);
}

function addFolder(
  state: ModelDetailsState,
  parent: Tree.FolderNode
): ModelDetailsState {
  const newFileTreeRoot = Tree.applyInsertFolder(
    state.edited.fileTreeRoot,
    parent
  );
  return applyFileUploads(state, newFileTreeRoot);
}

type AddUploadsAction = [
  "addUploads",
  {
    cancelToken: CancelTokenSource;
    filePathsToUpload: FilePath[];
    folderNode?: Tree.FolderNode;
  }
];

function addUploads(
  state: ModelDetailsState,
  cancelToken: CancelTokenSource,
  filePathsToUpload: FilePath[],
  folderNode?: Tree.FolderNode
): ModelDetailsState {
  const newFileTreeRoot = deepClone(state.edited.fileTreeRoot);

  const oldUploadParent = folderNode ?? state.edited.fileTreeRoot;
  const newUploadParent = Tree.findNodeById(
    newFileTreeRoot,
    oldUploadParent.id
  ) as Tree.FolderNode;
  newUploadParent.isExpanded = true;
  filePathsToUpload.forEach((filePath) => {
    const fileUpload = {
      status: UploadStatus.IN_PROGRESS,
      info: filePath.file,
      metadataScan: null,
      cancelToken: cancelToken,
    } as FileUpload;

    insertPath(newUploadParent, filePath.path, [fileUpload]);
  });

  return applyFileUploads(state, newFileTreeRoot);
}

function cancelFolderEdit(
  state: ModelDetailsState,
  editedFolder: Tree.FolderNode
): ModelDetailsState {
  const newFileTreeRoot = Tree.applyCancelFolderEdit(
    state.edited.fileTreeRoot,
    editedFolder
  );
  return applyFileUploads(state, newFileTreeRoot);
}

function commitFolderEdit(
  state: ModelDetailsState,
  editedFolder: Tree.FolderNode
): ModelDetailsState {
  const newFileTreeRoot = Tree.applyCommitFolderEdit(
    state.edited.fileTreeRoot,
    editedFolder
  );
  return applyFileUploads(state, newFileTreeRoot);
}

function deleteFileTreeNode(
  state: ModelDetailsState,
  deletedNode: Tree.FileTreeNode
): ModelDetailsState {
  let file: FileUpload = null;
  if (deletedNode.type === "File") {
    file = deletedNode.file;
    file.cancelToken?.cancel(`${file.info.name} upload cancelled`);
  }

  const newFileTreeRoot = Tree.applyDelete(
    state.edited.fileTreeRoot,
    deletedNode
  );
  const stateAfterRemoval = applyFileUploads(state, newFileTreeRoot);

  if (
    file &&
    state.selectedFileTreeNode?.type === "File" &&
    state.selectedFileTreeNode?.file?.info.id === file.info.id
  ) {
    return selectModelMetadata(stateAfterRemoval);
  }
  return stateAfterRemoval;
}

function toModelFields(
  schema: ModelMetadataSchema,
  metadataValues: Record<string, any>
): ModelFields {
  const allDefinedFields = defineAllFields(schema, metadataValues);
  const fieldsInTab = defineFieldsInTab(allDefinedFields);

  return {
    customSchema: schema,
    all: allDefinedFields,
    tab: fieldsInTab,
  };
}

type DiscardChangesAction = ["discardChanges"];

function discardChanges(state: ModelDetailsState): ModelDetailsState {
  const metadataValues = flattenPresentMetadata(state.original.model.metadata);
  const newState = selectModelMetadata(state);
  return {
    ...newState,
    fields: toModelFields(state.fields.customSchema, metadataValues),
    metadataValues,
    edited: { ...state.original },
    modelChanged: false,
  };
}

function allUploadsIn(node: Tree.FileTreeNode): FileUpload[] {
  if (node.type === "File") {
    return [node.file];
  }

  if (!node.children) {
    return [];
  }

  return node.children.flatMap((child) => allUploadsIn(child));
}

function applyFileUploads(
  state: ModelDetailsState,
  newFileTreeRoot: Tree.FolderNode
): ModelDetailsState {
  const fileUploads = allUploadsIn(newFileTreeRoot);

  const fileFolders = convertToFileIdFolders(newFileTreeRoot);

  const editedAfterUpdate: ModelContent = {
    fileTreeRoot: newFileTreeRoot,
    model: {
      ...state.edited.model,
      fileFolders,
      // TODO: This should be moved out; this should only happen when an file is
      // successfully uploaded!
      metadata: applyKeywords(fileUploads, state.edited.model.metadata),
    },
  };

  const newState = applyEdit(state, editedAfterUpdate);
  return newState;
}

function editFolderName(
  state: ModelDetailsState,
  editedFolder: Tree.FolderNode,
  newTitle: string
): ModelDetailsState {
  const newFileTreeRoot = Tree.applyEditFolderName(
    state.edited.fileTreeRoot,
    editedFolder,
    newTitle
  );
  // No change has been committed yet, so we don't need to rebuild the FileFolders.
  return {
    ...state,
    edited: {
      ...state.edited,
      fileTreeRoot: newFileTreeRoot,
    },
  };
}

type EditMarkingsAction = ["editMarkings", SecurityMarkings];

function editMarkings(
  state: ModelDetailsState,
  newMarkings: SecurityMarkings
): ModelDetailsState {
  const editedAfterUpdate: ModelContent = {
    ...state.edited,
    model: {
      ...state.edited.model,
      securityMarkings: newMarkings,
    },
  };
  return applyEdit(state, editedAfterUpdate);
}

type EditPropertyAction = [
  "editProperty",
  { propertyName: string; value: any }
];

function editProperty(
  state: ModelDetailsState,
  propertyName: string,
  value: any
): ModelDetailsState {
  const updatedMetadata = applyMetadataProperty(
    state.edited.model.metadata,
    propertyName,
    value
  );
  const editedAfterUpdate: ModelContent = {
    ...state.edited,
    model: {
      ...state.edited.model,
      metadata: updatedMetadata,
    },
  };
  const stateAfterEdit = applyEdit(state, editedAfterUpdate);
  // TODO: When we move to being able to remove fields, we may want to make
  // edit property only for setting values for properties that are defined,
  // and make separate actions for adding and removing properties.
  stateAfterEdit.fields = toModelFields(
    state.fields.customSchema,
    stateAfterEdit.metadataValues
  );
  return stateAfterEdit;
}

function defineAllFields(
  schema?: ModelMetadataSchema,
  customProps?: Record<string, any>
): FieldSpec[] {
  const allSchemaFields = allMetadataFields(schema);
  return concatUnspecifiedFields(allSchemaFields, customProps);
}

type ResetOriginalAndEditedAction = ["resetOriginalAndEdited", ModelInfo];

function resetOriginalAndEdited(
  state: ModelDetailsState,
  model: ModelInfo
): ModelDetailsState {
  const metadataValues = flattenPresentMetadata(model.metadata);

  const originalFileTreeRoot = convertToFileTree(model.fileFolders);

  const originalFileFolders = convertToFileIdFolders(originalFileTreeRoot);

  const originalModelContent: ModelContent = {
    fileTreeRoot: originalFileTreeRoot,
    model: {
      id: model.id,
      metadata: model.metadata,
      securityMarkings: model.securityMarkings,
      fileFolders: originalFileFolders,
    },
  };

  const newState: ModelDetailsState = {
    ...state,
    fields: toModelFields(state.fields.customSchema, metadataValues),
    metadataValues,
    original: originalModelContent,
    edited: deepClone(originalModelContent),
    selectedFileTreeNode: null,
    modelChanged: false,
    creationDate: model.creationDate,
    lastSaveDate: model.lastModifiedDate,
  };
  return selectModelMetadata(newState);
}

function selectFileTreeNode(
  state: ModelDetailsState,
  treeNode: Tree.FileTreeNode
): ModelDetailsState {
  const treeWithOldNodeDeselected = state.selectedFileTreeNode
    ? Tree.applySelection(
        state.edited.fileTreeRoot,
        state.selectedFileTreeNode,
        false
      )
    : state.edited.fileTreeRoot;

  const newFileTreeRoot = Tree.applySelection(
    treeWithOldNodeDeselected,
    Tree.findNodeById(treeWithOldNodeDeselected, treeNode.id),
    true
  );

  return {
    ...state,
    selectedFileTreeNode: Tree.findNodeById(newFileTreeRoot, treeNode.id),
    edited: {
      ...state.edited,
      fileTreeRoot: newFileTreeRoot,
    },
  };
}

function selectModelMetadata(state: ModelDetailsState): ModelDetailsState {
  return selectFileTreeNode(state, state.edited.fileTreeRoot);
}

function setFolderExpanded(
  state: ModelDetailsState,
  folderToChange: Tree.FolderNode,
  expanded: boolean
): ModelDetailsState {
  const newFileTreeRoot = Tree.applyExpanded(
    state.edited.fileTreeRoot,
    folderToChange,
    expanded
  );
  return {
    ...state,
    edited: {
      ...state.edited,
      fileTreeRoot: newFileTreeRoot,
    },
  };
}

type SetModelSchemaAction = ["setModelSchema", ModelMetadataSchema];

function setModelSchema(
  state: ModelDetailsState,
  newSchema: ModelMetadataSchema
): ModelDetailsState {
  return {
    ...state,
    fields: toModelFields(newSchema, state.metadataValues),
  };
}

function startEditFolderName(
  state: ModelDetailsState,
  folderToChange: Tree.FolderNode
): ModelDetailsState {
  const newFileTreeRoot = Tree.applyStartEditFolderName(
    state.edited.fileTreeRoot,
    folderToChange
  );
  return {
    ...state,
    edited: {
      ...state.edited,
      fileTreeRoot: newFileTreeRoot,
    },
  };
}

type UpdateFailedUploadsAction = ["updateFailedUploads", NewFileInfo[]];

function updateFailedUploads(
  state: ModelDetailsState,
  failedUploads: NewFileInfo[]
): ModelDetailsState {
  const failedFileUploads = failedUploads.map((failedUpload) =>
    toFileUploadFailure(failedUpload)
  );

  const newFileTreeRoot = Tree.applyReplaceFiles(
    state.edited.fileTreeRoot,
    failedFileUploads
  );
  return applyFileUploads(state, newFileTreeRoot);
}

type UpdateUploadsAction = ["updateSuccessfulUploads", FileInfo[]];

function updateSuccessfulUploads(
  state: ModelDetailsState,
  fileInfos: FileInfo[]
): ModelDetailsState {
  if (!fileInfos) {
    return;
  }

  const fileInfoAsUploads = fileInfos.map((fileInfo) =>
    toFileInfoUpload(fileInfo)
  );

  const newFileTreeRoot = Tree.applyReplaceFiles(
    state.edited.fileTreeRoot,
    fileInfoAsUploads
  );
  const newState = applyFileUploads(state, newFileTreeRoot);

  const newVersionOfSelectedFile = fileInfoAsUploads.find((fileUpload) => {
    if (state.selectedFileTreeNode?.type === "File") {
      return fileUpload.info.id === state.selectedFileTreeNode.file?.info.id;
    } else {
      return false;
    }
  });

  if (newVersionOfSelectedFile && state.selectedFileTreeNode?.type === "File") {
    const updatedFileTreeNode: Tree.FileNode = {
      ...state.selectedFileTreeNode,
      file: newVersionOfSelectedFile,
    };
    newState.selectedFileTreeNode = updatedFileTreeNode;
  }

  return newState;
}

export type ModelDetailsPageAction =
  | AddUploadsAction
  | DiscardChangesAction
  | EditMarkingsAction
  | EditPropertyAction
  | Tree.ModelFileTreeAction
  | ResetOriginalAndEditedAction
  | SetModelSchemaAction
  | UpdateFailedUploadsAction
  | UpdateUploadsAction;

export function ModelDetailsReducer(
  state: ModelDetailsState,
  action: ModelDetailsPageAction
): ModelDetailsState {
  const actionType = action[0];
  switch (actionType) {
    case "addFolder":
      return addFolder(state, action[1]);
    case "addUploads":
      return addUploads(
        state,
        action[1].cancelToken,
        action[1].filePathsToUpload,
        action[1].folderNode
      );
    case "cancelFolderEdit":
      return cancelFolderEdit(state, action[1]);
    case "commitFolderEdit":
      return commitFolderEdit(state, action[1]);
    case "deleteFileTreeNode":
      return deleteFileTreeNode(state, action[1]);
    case "discardChanges":
      return discardChanges(state);
    case "editFolderName":
      return editFolderName(state, action[1].editedFolder, action[1].newTitle);
    case "editMarkings":
      return editMarkings(state, action[1]);
    case "updateFailedUploads":
      return updateFailedUploads(state, action[1]);
    case "editProperty":
      return editProperty(state, action[1].propertyName, action[1].value);
    case "resetOriginalAndEdited":
      return resetOriginalAndEdited(state, action[1]);
    case "selectFileTreeNode":
      return selectFileTreeNode(state, action[1]);
    case "setFolderExpanded":
      return setFolderExpanded(
        state,
        action[1].folderToChange,
        action[1].expanded
      );
    case "setModelSchema":
      return setModelSchema(state, action[1]);
    case "startEditFolderName":
      return startEditFolderName(state, action[1]);
    case "updateSuccessfulUploads":
      return updateSuccessfulUploads(state, action[1]);
    default:
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
  }
}

/***********************************************************
 * Helper functions for converting between api representations
 * of the file tree and ones that are displayable in a tree.
 ***********************************************************/

export function convertToFileTree(
  fileFolders?: ModelFileFolder[]
): Tree.FolderNode {
  const modelRootNode = Tree.newFolder("Model", Tree.ROOT_FILE_TREE_NODE_ID);
  modelRootNode.isExpanded = true;

  if (fileFolders) {
    fileFolders.forEach((folder) => {
      const modelFilesAsUploads =
        folder.files?.map((file) => toClearedModelFileUpload(file)) ?? [];
      const pathWithoutLeadingSlash = folder.path.startsWith("/")
        ? folder.path.substring(1)
        : folder.path;
      insertPath(modelRootNode, pathWithoutLeadingSlash, modelFilesAsUploads);
    });
  }

  return modelRootNode;
}

function insertPath(
  subroot: Tree.FolderNode,
  path: string,
  fileUploads: FileUpload[]
): void {
  if (!path || path === "") {
    const fileNodes = fileUploads.map((fileUpload) => Tree.newFile(fileUpload));
    subroot.children.push(...fileNodes);
    subroot.children.sort(Tree.compareFileTreeNodes);
    return;
  }

  const nextChildPath = Tree.childPath(path);
  const childFolder = acquireChildFolder(subroot, nextChildPath.child);
  insertPath(childFolder, nextChildPath.descendantPathAfterChild, fileUploads);
}

// NOTE: This function has a side-effect; it will modify the subroot's children.
function acquireChildFolder(
  subroot: Tree.FolderNode,
  folderName: string
): Tree.FolderNode {
  const firstFolderWithName = subroot.children.filter((child) => {
    return child.type === "Folder" && equalIgnoreCase(child.title, folderName);
  })[0];

  if (firstFolderWithName) {
    return firstFolderWithName as Tree.FolderNode;
  } else {
    const newFolder = Tree.newFolder(folderName);

    subroot.children.push(newFolder);
    subroot.children.sort(Tree.compareFileTreeNodes);
    return newFolder;
  }
}

function toFileIdFolders(
  basePath: string,
  subroot: Tree.FolderNode
): FileIdFolder[] {
  const filesInCurrentSubroot: UUID[] = [];
  const allFolders: FileIdFolder[] = [];
  subroot.children?.forEach((child) => {
    switch (child.type) {
      case "File":
        // Only include files that aren't redacted; the server
        // will handle data from any redactions.
        if (child.file?.info?.id) {
          filesInCurrentSubroot.push(child.file.info.id);
        }
        break;
      case "Folder":
        const subfolderPath = `${basePath}/${child.title}`;
        const subfolders = toFileIdFolders(subfolderPath, child);
        allFolders.push(...subfolders);
        break;
      default:
        const _exhaustiveCheck: never = child;
        return _exhaustiveCheck;
    }
  });
  if (filesInCurrentSubroot.length > 0 || allFolders.length == 0) {
    const currentFolder: FileIdFolder = {
      path: basePath,
      fileIds: filesInCurrentSubroot,
    };
    allFolders.push(currentFolder);
  }
  return allFolders;
}

function convertToFileIdFolders(root: Tree.FolderNode): FileIdFolder[] {
  return toFileIdFolders("", root);
}
