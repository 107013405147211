/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { useState } from "react";
import { FileUploader } from "baseui/file-uploader";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";
import { tagListFieldSpec } from "../Shared/FieldSpec";
import { notify, notifyBatchResponse } from "../Shared/Notify";
import { RightAlignedRow, DirectionalStack } from "../DesignSystem/Containers";
import MetadataPropsEditor from "../MetadataEditor/MetadataPropsEditor";
import axios from "axios";
import { AxiosContext } from "../Utils/AuthContext";
import {
  BulkAddModelResponse,
  ElementUploadFailure,
  ElementUploadSuccess,
} from "Api";
import { useApolloClient } from "@apollo/client";

const getAllFailedMessage = (
  failedElements?: ElementUploadFailure[]
): string => {
  const errorMessages = failedElements.map((element) => element.failureMessage);
  const uniqueErrorMessages = [...new Set(errorMessages)].join(", ");
  return `Failed to upload all files because of ${uniqueErrorMessages}.`;
};

const getPartialSuccessMessage = (
  failedElements: ElementUploadFailure[],
  successfulElements?: ElementUploadSuccess[],
  duplicateElements?: string[]
): string => {
  const failedFileNames = failedElements
    .map((element) => element.uploadedFileName)
    .join(", ");

  if (duplicateElements) {
    return `Added ${successfulElements.length} models from files; ${duplicateElements.length} files were previously uploaded. Could not upload ${failedFileNames}.`;
  } else {
    return `Added ${successfulElements.length} models from files. Could not upload ${failedFileNames}.`;
  }
};

const getSingleSuccessMessage = (
  successfulElement: ElementUploadSuccess
): string => {
  return `Added new model from ${successfulElement.uploadedFileName}`;
};

const getFewSuccessMessage = (
  successfulElements: ElementUploadSuccess[]
): string => {
  const successfulFileNames = successfulElements
    .map((element) => element.uploadedFileName)
    .join(", ");
  return `Added models from ${successfulFileNames}`;
};

const getManySuccessMessage = (
  successfulElements: ElementUploadSuccess[]
): string => {
  return `Added ${successfulElements.length} models from files.`;
};

const getSuccessMessageManyDuplicate = (
  successfulElements: ElementUploadSuccess[],
  duplicateElements: string[]
): string => {
  return `Added ${successfulElements.length} model(s) from files; ${duplicateElements.length} files were previously uploaded.`;
};

const getSuccessMessageSingleDuplicate = (
  successfulElements: ElementUploadSuccess[],
  duplicateElement: string
): string => {
  return `Added ${successfulElements.length} model(s) from files. ${duplicateElement} was previously uploaded.`;
};

const notifyBulkAddResponse = (bulkAddResponse: BulkAddModelResponse): void => {
  const { successfulUploads, failedUploads, operationError } = bulkAddResponse;

  const duplicateUploads = successfulUploads
    ?.filter((success) => success.isDuplicate)
    .map((success) => success.uploadedFileName);

  if (operationError) {
    notify.negative(`Bulk upload could not start because ${operationError}`);
  } else {
    notifyBatchResponse(
      successfulUploads,
      duplicateUploads,
      failedUploads,
      getAllFailedMessage,
      getPartialSuccessMessage,
      getSingleSuccessMessage,
      getFewSuccessMessage,
      getManySuccessMessage,
      getSuccessMessageManyDuplicate,
      getSuccessMessageSingleDuplicate
    );
  }
};

export const BulkAddModal = ({
  isOpen,
  refetchQueryName,
  onClose,
}: {
  isOpen: boolean;
  refetchQueryName: string;
  onClose: () => void;
}): JSX.Element => {
  const [keywords, setKeywords] = useState<string[]>([]);

  const axiosContext = React.useContext(AxiosContext);
  const apolloContext = useApolloClient();

  const closeModal = React.useCallback(() => {
    setKeywords([]);
    onClose();
  }, [onClose]);

  const handleFilesSelected = React.useCallback(
    async (files: File[]): Promise<void> => {
      // TODO: Commonize with ObservableFileUploader
      const postToken = axios.CancelToken.source();

      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`${index}`, file, file.name);
      });

      keywords.forEach((keyword, index) => {
        formData.append(`keyword-${index}`, keyword);
      });

      try {
        const httpBulkAddResponse = await axiosContext.post(
          "/bulkAddModels",
          formData,
          {
            cancelToken: postToken.token,
          }
        );

        const bulkAddResponse =
          httpBulkAddResponse.data as BulkAddModelResponse;
        notifyBulkAddResponse(bulkAddResponse);
      } catch (reason) {
        if (axios.isCancel(reason)) {
          // User cancelled; no notification necessary.
          return;
        }
      } finally {
        apolloContext.refetchQueries({ include: [refetchQueryName] });
      }

      closeModal();
    },
    [closeModal, axiosContext, apolloContext, refetchQueryName, keywords]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      // The unstable_ModalBackdropScroll will be removed in the next major baseui version.
      // It was recommended to set it to true to prepare for its removal.
      unstable_ModalBackdropScroll={true}
    >
      <ModalHeader>Bulk Add Models</ModalHeader>
      <ModalBody>
        <DirectionalStack>
          <MetadataPropsEditor
            fields={[tagListFieldSpec("Keywords", "keywords")]}
            metadataValues={{ keywords: keywords }}
            onValueChange={(_, value) => {
              setKeywords(value);
            }}
          />
          {`Choose files to upload. Each file will become a new model.`}
          <BulkAddFileSelector onFilesSelected={handleFilesSelected} />
        </DirectionalStack>
      </ModalBody>
      <ModalFooter>
        <RightAlignedRow>
          <ModalButton kind="tertiary" onClick={closeModal}>
            Cancel
          </ModalButton>
        </RightAlignedRow>
      </ModalFooter>
    </Modal>
  );
};

const BulkAddFileSelector = ({
  onFilesSelected,
}: {
  onFilesSelected: (files: File[]) => void;
}): JSX.Element => {
  const [isUploading, setIsUploading] = React.useState(false);
  function reset(): void {
    onFilesSelected([]);
    setIsUploading(false);
  }

  return (
    <>
      <FileUploader
        multiple={true}
        onCancel={reset}
        onDrop={async (acceptedFiles, _) => {
          try {
            setIsUploading(true);
            onFilesSelected(acceptedFiles);
          } finally {
            setIsUploading(false);
          }
        }}
        progressMessage={isUploading ? `Uploading... hang tight.` : ""}
      />
    </>
  );
};
