/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import ReactFlow, {
  Controls,
  Background,
  Connection,
  NodeChange,
  EdgeChange,
  useUpdateNodeInternals,
  KeyCode,
} from "reactflow";
import "reactflow/dist/style.css";
import { useCallback, useContext, useMemo, useRef } from "react";

import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import { Button, KIND } from "baseui/button";
import { ChevronDown, Upload } from "baseui/icon";
import { FaDatabase, FaDownload, FaTrashAlt } from "react-icons/fa";
import { OverflowActions } from "../Shared/OverflowMenu";

import { UUID } from "../Utils/Types";
import { ReactFlowEdge, isNodeTitleValid } from "./WorkbenchData";
import { CSVNodeComponent } from "./CSVNode";
import { EWIRDBNodeComponent } from "./EWIRDBNode";
import { DividedListNodeComponent } from "./DividedListNode";
import { HeaderNavigation } from "baseui/header-navigation";
import { HorizontalSpacer } from "../DesignSystem/Containers";
import { TetherPlacement } from "baseui/layer";
import { WorkbenchNodeComponent } from "./WorkbenchNode";
import { fileDownload } from "../Utils/Common";
import { ProjectContext } from "../UserApp";
import { COMMON_TEXT_STYLING, TOP_ITEM_ZINDEX } from "../Utils/SiteProps";
import { ProjectStorableState, stringify } from "../ProjectState";
import { JavaScriptNodeComponent } from "./JavaScriptNode";
import { ModifiableDividedListNodeComponent } from "./ModifiableDividedListNode";
import Select from "react-select";
import { DeleteConfirmationModal } from "../Shared/DeleteConfirmationModal";
import { BiEdit } from "react-icons/bi";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";

export function Workbench(): JSX.Element {
  const projectContext = useContext(ProjectContext);
  const { projectState, projectDispatch } = projectContext;
  const {
    bufferedFormats,
    bufferedTransforms,
    bufferedSources,
    bufferedProjects,
  } = projectState;

  const updateNodeInternals = useUpdateNodeInternals();
  const [isMouseOverDownload, setIsMouseOverDownload] = React.useState(false);
  const [isMouseOverSave, setIsMouseOverSave] = React.useState(false);
  const [isMouseOverUpload, setIsMouseOverUpload] = React.useState(false);
  const [isMouseOverClear, setIsMouseOverClear] = React.useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    React.useState(false);

  // data that shouldn't change
  const nodeTypes = useMemo(
    () => ({
      dataFormatNode: WorkbenchNodeComponent,
      csvFormatNode: CSVNodeComponent,
      ewirdbFormatNode: EWIRDBNodeComponent,
      transformTypeNode: DividedListNodeComponent,
      modifiableTransformTypeNode: ModifiableDividedListNodeComponent,
      javascriptTransformTypeNode: JavaScriptNodeComponent,
    }),
    []
  );

  const deleteKeyCodes = useMemo((): KeyCode => {
    return ["Delete", "Backspace"];
  }, []);

  // callbacks for connecting React Flow to the state machine
  const onNodesChange = useCallback(
    (changes: NodeChange[]) => projectDispatch(["changeNodes", { changes }]),
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      projectDispatch(["changeEdges", { changes }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const onConnect = useCallback(
    (params: Connection | ReactFlowEdge) => {
      projectDispatch(["connect", { params }]);
      updateNodeInternals(params.target);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // callbacks for various buttons and controls
  const onClickFormat = useCallback(
    (formatId: UUID) => {
      const descriptor = bufferedFormats.find((it) => it.id == formatId);
      projectDispatch(["createNodeFromDescriptor", { descriptor }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bufferedFormats]
  );
  const onClickTransform = useCallback(
    (transformId: UUID) => {
      const descriptor = bufferedTransforms.find((it) => it.id == transformId);
      projectDispatch(["createNodeFromDescriptor", { descriptor }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bufferedTransforms]
  );
  //Loads stored project data from selected project in dropdown
  const onClickProject = useCallback(
    (projectId: UUID) => {
      const project = bufferedProjects.find((it) => it.projectID == projectId);
      projectDispatch(["loadProjectFromData", { project }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bufferedProjects]
  );
  //Clears data and creates a new project
  const onClear = useCallback(
    () => {
      projectDispatch([
        "loadProjectFromData",
        { project: new ProjectStorableState() },
      ]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const onRegisterMapping = useCallback(
    () => projectDispatch(["registerMapping", {}, projectDispatch]),
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const onPerformMapping = useCallback(
    (sourceModelID: UUID) =>
      projectDispatch(["performMapping", { sourceModelID }, projectDispatch]),
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const setSingleOrMultipleOutputFiles = useCallback(
    (multipleInputFilesToMultipleOutputFiles: boolean) => {
      projectDispatch([
        "setSingleOrMultipleOutputFiles",
        { multipleInputFilesToMultipleOutputFiles },
      ]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setMultipleCSVsOrExcelFile = useCallback(
    (multipleCSVsToSingleExcelOutput: boolean) => {
      projectDispatch([
        "setMultipleCSVsOrExcelFile",
        { multipleCSVsToSingleExcelOutput },
      ]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const startProjectTitleEditing = useCallback(
    () => {
      projectDispatch(["startProjectTitleEditing"]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateProjectTitle = useCallback(
    (wipTitle: string) => {
      projectDispatch(["updateProjectTitle", { wipTitle }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const confirmProjectTitleEdit = useCallback(
    () => {
      projectDispatch(["confirmProjectTitleEdit"]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const discardProjectTitleEdit = useCallback(
    () => {
      projectDispatch(["discardProjectTitleEdit"]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleEdgeClick = useCallback(() => {
    projectDispatch(["edgeClicked"]);
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customStyles = {
    option: (styles) => ({
      ...styles,
      cursor: "pointer",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
    }),
  };

  // saving and loading
  const onDownload = useCallback(() => {
    const stringifiedData = stringify(projectState);
    const nodeData = new Blob([stringifiedData], {
      type: "application/json",
    });
    const filename = projectState.projectName + ".json";
    fileDownload(nodeData, filename);
  }, [projectState]);
  const uploadFile = useCallback(
    (changeEvent) => {
      changeEvent.preventDefault();
      const file = changeEvent.target.files[0] as File;
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        const fileJsonText = loadEvent.target.result as string;
        projectDispatch([
          "loadProjectFromJson",
          { json: fileJsonText, source: file.name },
        ]);
      };
      reader.readAsText(file);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const showValidationError =
    (projectState.projectTitleInProgress ?? "").length > 0 &&
    !isNodeTitleValid(projectState.projectTitleInProgress ?? "");

  //Hides HTML file browser button, but links browser functionality to the baseweb upload file button
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const onUploadClick = useCallback(() => {
    hiddenFileInput.current?.click();
  }, []);

  const horizontalGap = "10px";
  const endChevron = <ChevronDown size={24} />;

  const formatSelectActions: OverflowActions[] = bufferedFormats.map((it) => {
    return { label: it.displayName, onClick: () => onClickFormat(it.id) };
  });
  const transformSelectActions: OverflowActions[] = bufferedTransforms.map(
    (it) => {
      return { label: it.displayName, onClick: () => onClickTransform(it.id) };
    }
  );
  const projectSelectActions: OverflowActions[] = bufferedProjects.map((it) => {
    return {
      label: it.projectName,
      onClick: () => onClickProject(it.projectID),
    };
  });
  const sourceSelectActions: OverflowActions[] = bufferedSources.map(
    (source) => {
      return {
        label: source.name,
        onClick: () => {
          onPerformMapping(source.id);
        },
      };
    }
  );

  const outputOptions = [
    { value: 1, label: "Combine multiple inputs" },
    { value: 2, label: "Keep multiple inputs distinct" },
  ];
  const csvOrSheetOption = [
    {
      value: 1,
      label: "Zip multiple CSVs",
    },
    {
      value: 2,
      label: "CSVs to Excel workbook",
    },
  ];

  return (
    <>
      <HeaderNavigation>
        <HorizontalSpacer width={horizontalGap} />

        <Button
          onMouseOver={() => setIsMouseOverClear(true)}
          onMouseOut={() => setIsMouseOverClear(false)}
          onClick={() =>
            projectState.rfNodes.length > 0
              ? setIsDeleteConfirmationOpen(true)
              : onClear()
          }
          kind={KIND.primary}
          style={{ width: "50px" }}
          startEnhancer={<FaTrashAlt style={{ marginLeft: "10px" }} />}
        >
          {isMouseOverClear && (
            <div
              style={{
                width: "auto",
                height: "auto",
                fontSize: "12px",
                backgroundColor: "black",
                color: "#fff",
                padding: "5px",
                textAlign: "left",
                borderRadius: "6px",
                position: "absolute",
                marginTop: "8rem",
                marginLeft: "4rem",
                zIndex: 1,
              }}
            >
              <p>Clear the workbench</p>
            </div>
          )}
        </Button>
        <DeleteConfirmationModal
          isOpen={isDeleteConfirmationOpen}
          header={"Clear workbench"}
          bodyText={`Are you sure you want to clear the workbench? This will remove all nodes and edges.`}
          onDelete={() => {
            onClear();
            setIsDeleteConfirmationOpen(false);
          }}
          onCancel={() => setIsDeleteConfirmationOpen(false)}
        />

        <HorizontalSpacer width={horizontalGap} />

        <Button
          onMouseOver={() => setIsMouseOverSave(true)}
          onMouseOut={() => setIsMouseOverSave(false)}
          //Placeholder log until backend code has been completed
          onClick={onRegisterMapping}
          kind={KIND.primary}
          style={{ width: "50px" }}
          startEnhancer={
            <FaDatabase
              style={{
                marginLeft: "10px",
              }}
            />
          }
        >
          {isMouseOverSave && (
            <div
              style={{
                width: "auto",
                height: "auto",
                fontSize: "12px",
                backgroundColor: "black",
                color: "#fff",
                padding: "5px",
                textAlign: "left",
                borderRadius: "6px",
                position: "absolute",
                marginTop: "8rem",
                marginLeft: "9rem",
                zIndex: 1,
              }}
            >
              <p>Register current mapping with database</p>
            </div>
          )}
        </Button>

        <HorizontalSpacer width={horizontalGap} />

        <Button
          onMouseOver={() => setIsMouseOverDownload(true)}
          onMouseOut={() => setIsMouseOverDownload(false)}
          onClick={onDownload}
          kind={KIND.primary}
          style={{ width: "50px" }}
          startEnhancer={<FaDownload style={{ marginLeft: "10px" }} />}
        >
          {isMouseOverDownload && (
            <div
              style={{
                width: "auto",
                height: "auto",
                fontSize: "12px",
                backgroundColor: "black",
                color: "#fff",
                padding: "5px",
                textAlign: "left",
                borderRadius: "6px",
                position: "absolute",
                marginTop: "8rem",
                marginLeft: "6.5rem",
                zIndex: 1,
              }}
            >
              <p>Download current mapping</p>
            </div>
          )}{" "}
        </Button>

        <HorizontalSpacer width={horizontalGap} />

        <label htmlFor={"upload-button"}>
          <Button
            onMouseOver={() => setIsMouseOverUpload(true)}
            onMouseOut={() => setIsMouseOverUpload(false)}
            onClick={onUploadClick}
            kind={KIND.primary}
            size={"large"}
            style={{ height: "52px", width: "50px" }}
            startEnhancer={<Upload size={23} style={{ marginLeft: "10px" }} />}
          >
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={uploadFile}
              style={{ display: "none" }}
            />

            {isMouseOverUpload && (
              <div
                style={{
                  width: "auto",
                  height: "auto",
                  fontSize: "12px",
                  backgroundColor: "black",
                  color: "#fff",
                  padding: "5px",
                  textAlign: "left",
                  borderRadius: "6px",
                  position: "absolute",
                  marginTop: "8.25rem",
                  marginLeft: "10rem",
                  zIndex: 1,
                }}
              >
                <p>Upload mapping from your file system</p>
              </div>
            )}
          </Button>
        </label>

        <HorizontalSpacer width={horizontalGap} />

        <ItemSelectorDropdown
          title="Registered mappings"
          itemSelectActions={projectSelectActions}
          kind={KIND.secondary}
          endEnhancer={endChevron}
        />

        <HorizontalSpacer width={horizontalGap} />

        <ItemSelectorDropdown
          title="Format"
          itemSelectActions={formatSelectActions}
          kind={KIND.secondary}
          endEnhancer={endChevron}
        />

        <HorizontalSpacer width={horizontalGap} />

        <ItemSelectorDropdown
          title="Transform"
          itemSelectActions={transformSelectActions}
          kind={KIND.secondary}
          endEnhancer={endChevron}
        />

        <HorizontalSpacer width={horizontalGap} />

        <ItemSelectorDropdown
          title="Map with"
          itemSelectActions={sourceSelectActions}
          kind={KIND.secondary}
          endEnhancer={endChevron}
        />

        <div
          style={{
            display: "flex",
            flexGrow: "100",
          }}
        ></div>

        <div style={{ marginTop: "10px", maxWidth: "22rem" }}>
          <Select
            defaultValue={outputOptions[0]}
            onChange={(e) => {
              setSingleOrMultipleOutputFiles(e.value == 2);
            }}
            options={outputOptions}
            placeholder="Single or multiple outputs?"
            styles={customStyles}
          />
        </div>

        <HorizontalSpacer width={horizontalGap} />

        <div
          style={{ marginTop: "10px", marginRight: "3rem", maxWidth: "20rem" }}
        >
          <Select
            defaultValue={csvOrSheetOption[0]}
            onChange={(e) => {
              setMultipleCSVsOrExcelFile(e.value == 2);
            }}
            options={csvOrSheetOption}
            placeholder="Save Excel or ZIP?"
            styles={customStyles}
          />
        </div>
      </HeaderNavigation>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center",
          rowGap: "1rem",
          height: "75vh",
        }}
      >
        <div style={{ ...COMMON_TEXT_STYLING }}>
          {!projectState.projectTitleEditInProgress ? (
            <>
              {projectState.projectName}{" "}
              <Button
                size="mini"
                kind="tertiary"
                onClick={() => startProjectTitleEditing()}
              >
                <BiEdit />
              </Button>
            </>
          ) : (
            <div className="nodrag">
              <FormControl
                error={showValidationError && "Title must be a valid file name"}
              >
                <Input
                  autoFocus
                  value={projectState.projectTitleInProgress ?? ""}
                  type="text"
                  size="mini"
                  onChange={(e) => {
                    updateProjectTitle(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // whitespace-only fields are not allowed
                      if (
                        projectState.projectTitleInProgress.trim().length == 0
                      ) {
                        discardProjectTitleEdit();
                      } else if (showValidationError) {
                        // do nothing
                      } else {
                        confirmProjectTitleEdit();
                      }
                    } else if (e.key === "Escape") {
                      discardProjectTitleEdit();
                    }
                    e.stopPropagation();
                  }}
                  onBlur={() => discardProjectTitleEdit()}
                ></Input>
              </FormControl>
            </div>
          )}
        </div>

        <ReactFlow
          onEdgeClick={handleEdgeClick}
          deleteKeyCode={projectState.edgeClicked && deleteKeyCodes}
          nodes={projectState.rfNodes}
          edges={projectState.rfEdges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          snapToGrid={true}
          nodeTypes={nodeTypes}
        >
          <Background />
          <Controls />
        </ReactFlow>
      </div>
    </>
  );
}

interface ItemSelectorDropdownProps {
  title: string;
  itemSelectActions: OverflowActions[];
  placement?: TetherPlacement;
  kind?: keyof typeof KIND;
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
}

function ItemSelectorDropdown(props: ItemSelectorDropdownProps): JSX.Element {
  return (
    <>
      <StatefulPopover
        overrides={{
          Body: {
            style: {
              zIndex: TOP_ITEM_ZINDEX,
            },
          },
        }}
        placement={props.placement ?? PLACEMENT.bottomRight}
        content={({ close }) => (
          <StatefulMenu
            items={props.itemSelectActions}
            onItemSelect={(selectedItem) => {
              selectedItem.item.onClick();
              close();
            }}
            overrides={{
              List: {
                style: {
                  height: "auto",
                  width: "200px",
                },
              },
            }}
          />
        )}
      >
        <Button
          startEnhancer={props.startEnhancer}
          kind={props.kind ?? KIND.primary}
          endEnhancer={props.endEnhancer}
        >
          {props.title}
        </Button>
      </StatefulPopover>
    </>
  );
}
